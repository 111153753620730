import React from 'react'
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'
import App from '../../App'
import { LandingPage } from '../Features/LandingPage/LandingPage'
import ProductView from '../Features/ProductView/ProductView'
import UserProfile from '../Features/UserProfile/Profile'

export const AppRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="" element={<LandingPage />} />
      <Route path="course_view" element={<ProductView />} />
      <Route path="profile" element={<UserProfile />} />
    </Route>,
  ),
)

export const staticUserObj = { name: 'John', age: 30, enrolled_cources: [], login: true }

export const courses = [
  {
    title: 'Web Development',
    description: 'Advanced Web Development',
    img: 'https://i.pinimg.com/564x/51/b2/fe/51b2fee7367115957f29cb0add059017.jpg',
    careerOutcome: 'Fullstack web developer',
    learningInsight:
      'Flullstack, Data Structures and Algorithms, DevOps Tools, CICD, System Design, Advanced DSA',
  },
  {
    title: 'App Development',
    description: 'Introduction to Programming',
    img: 'https://i.pinimg.com/564x/88/84/bd/8884bd0f2adfa3e54c0fd2116a883f82.jpg',
    careerOutcome: 'App designer and developer',
    learningInsight:
      'Core Java, Advance Java, Spring Boot,  Data Structures and Algorithms, Linux, DevOps Tools, AWS, System Design, Advanced DSA',
  },
  {
    title: 'Devops',
    description: 'Begin Your Path To Becoming A Top DevOps Engineer',
    img: 'https://i.pinimg.com/736x/79/1a/3b/791a3b29b6ee413950febc3bd94b5364.jpg',
    careerOutcome: 'System Reliability Engineer (SRE), Platform Engineer, Cloud Engineer/Architect',
    learningInsight:
      'Python Fundamentals, Data Structures and Algorithms, Linux, DevOps Tools, AWS, System Design, Advanced DSA',
  },
  {
    title: 'Data Science',
    description: 'Data Science Fundamentals',
    img: 'https://i.pinimg.com/564x/d0/d5/88/d0d588e3d1b7e95ade7ec448ecf2807c.jpg',
    careerOutcome: 'Data Analyst, Data Scientist, ML Engineer',
    learningInsight:
      'Tableau, SQL, Python, Data Analysis and Visualization, Machine Learning and Deep Learning, Machine Learning Ops, Advanced DSA',
  },
]

export const testimonials = [
  {
    name: 'John Doe',
    text: 'Great platform for learning new skills!',
  },
  {
    name: 'Jane Smith',
    text: 'The courses are well-structured and informative.',
  },
  {
    name: 'Emily Johnson',
    text: 'I highly recommend this for anyone looking to advance their career.',
  },
]

export const usp = [
  {
    title: 'Be Mentored 1:1 by Experienced Professionals',
    description:
      'Get senior industry experts as mentors to guide you with mock interviews, career advice, resume review, etc.',
    image:
      'https://assets-global.website-files.com/60f120767a8f0187072655dc/611b322a562844c5711a7fb8_S5JIak1Rrpa4CSNeEfWMszgtfC-5OFBD4v-XsL2B3dNg4s5jppIGMFopLV0I-HRMx-bmVgj4owWDfChcf8hlOFqNrLquZDNxSxPFQRsravFXTklaMUI3fa3Vp_Dw0zCvtWkIp8Xr.png',
    color: 'rgb(135, 74, 197)',
    backgroundColor: 'rgba(165, 125, 204, 0.226)',
  },
  {
    title: 'Become part of a thriving community for life',
    description:
      'As part of our extensive alumni community you will find job referrals, career advice, life advice, or your future co-founder - all in one place.',
    image:
      'https://cdn.prod.website-files.com/6384aadeeb9aef4298860dd3/6466fc4234a7324e51fd3491_annie-spratt-QckxruozjRg-unsplash.jpeg',
    color: '#FC8500',
    backgroundColor: '#f5c69141',
  },
  {
    title: 'A structured & flexible program, that cares for you',
    description:
      'You begin either as a Beginner, Intermediate, or Advanced learner based on your expertise.',
    image: 'https://bigdatatechwarsaw.eu/wp-content/uploads/2021/02/Przemys%C5%82aw-Biecek.jpg',
    color: '#2563EB',
    backgroundColor: '#749aec3b',
  },
]

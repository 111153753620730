export const CertificationSvg = () => (
  <svg
    height="70px"
    width="100px"
    version="1.1"
    id="Uploaded to svgrepo.com"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    style={{
      border: '0.1px solid grey',
      borderBottom: '4px solid grey',
      background: 'white',
      borderRadius: '10%',
    }}
  >
    <g>
      <path
        class="hatch_twee"
        fill="#FFC5BB"
        d="M21.641,23.144l5.498-5.498c0.006,0.117,0.019,0.228,0.058,0.322c0.08,0.193,0.276,0.368,0.492,0.542
		l-5.177,5.177c-0.174-0.215-0.348-0.411-0.542-0.492C21.875,23.158,21.759,23.15,21.641,23.144z M19.803,21.031
		c0.147,0.355,0.001,0.913-0.011,1.376l6.619-6.619c-0.312,0.008-0.67,0.08-0.977,0.08c-0.149,0-0.287-0.017-0.404-0.065
		c-0.01-0.004-0.02-0.014-0.031-0.019L19.785,21C19.79,21.011,19.799,21.021,19.803,21.031z M19.803,17.969
		C19.583,18.5,18.5,18.89,18.5,19.5c0,0.057,0.011,0.117,0.028,0.171l5.143-5.143C23.618,14.51,23.557,14.5,23.5,14.5
		c-0.61,0-1,1.083-1.531,1.303c-0.116,0.048-0.254,0.065-0.403,0.065c-0.19,0-0.397-0.024-0.602-0.046l-1.144,1.144
		C19.859,17.331,19.912,17.706,19.803,17.969z M26.467,23.211c0.23,0,0.433-0.04,0.569-0.175c0.288-0.288,0.146-0.876,0.107-1.394
		l-1.505,1.505C25.913,23.167,26.205,23.211,26.467,23.211z M24.769,28.016l0.786,0.628L26,28.199v-1.414L24.769,28.016z M21,24.177
		v1.022l0.799-0.799c-0.072-0.091-0.161-0.203-0.225-0.268c0,0,0,0-0.001,0c-0.003,0-0.006,0-0.009,0
		c-0.128,0-0.267,0.018-0.406,0.033L21,24.177z M25.841,24.165c-0.139-0.016-0.278-0.033-0.406-0.033c-0.003,0-0.006,0-0.009,0
		c-0.07,0.072-0.175,0.205-0.25,0.299C24.839,24.857,24.33,25.5,23.5,25.5c-0.069,0-0.132-0.011-0.196-0.019L21,27.785V29
		l0.995-0.796L26,24.199v-0.022C25.948,24.172,25.889,24.171,25.841,24.165z"
      />
      <path
        class="hatch_een"
        fill="#265AA5"
        d="M23.5,10h-20C3.224,10,3,9.776,3,9.5S3.224,9,3.5,9h20C23.776,9,24,9.224,24,9.5S23.776,10,23.5,10z
		 M14,12.5c0-0.276-0.224-0.5-0.5-0.5h-10C3.224,12,3,12.224,3,12.5S3.224,13,3.5,13h10C13.776,13,14,12.776,14,12.5z M14,15.5
		c0-0.276-0.224-0.5-0.5-0.5h-10C3.224,15,3,15.224,3,15.5S3.224,16,3.5,16h10C13.776,16,14,15.776,14,15.5z M14,23.5
		c0-0.276-0.224-0.5-0.5-0.5h-10C3.224,23,3,23.224,3,23.5S3.224,24,3.5,24h10C13.776,24,14,23.776,14,23.5z M32,7v19
		c0,0.552-0.448,1-1,1h-4v2c0,0.384-0.22,0.735-0.567,0.901C26.295,29.968,26.147,30,26,30c-0.223,0-0.444-0.075-0.625-0.219
		l-1.875-1.5l-1.875,1.5C21.444,29.925,21.223,30,21,30c-0.147,0-0.295-0.032-0.433-0.099C20.22,29.735,20,29.384,20,29v-2H1
		c-0.552,0-1-0.448-1-1V7c0-0.552,0.448-1,1-1h30C31.552,6,32,6.448,32,7z M26,24.177c-0.052-0.005-0.111-0.006-0.159-0.012
		c-0.139-0.016-0.278-0.033-0.406-0.033c-0.003,0-0.006,0-0.009,0c-0.07,0.072-0.175,0.205-0.25,0.299
		C24.839,24.857,24.33,25.5,23.5,25.5s-1.339-0.643-1.675-1.069c-0.074-0.094-0.179-0.227-0.25-0.299c0,0,0,0,0,0
		c-0.003,0-0.006,0-0.009,0c-0.128,0-0.267,0.018-0.406,0.033c-0.049,0.005-0.108,0.006-0.159,0.012V29l2.5-2l2.5,2V24.177z
		 M27.035,23.036c0.414-0.414-0.067-1.455,0.161-2.005C27.417,20.5,28.5,20.11,28.5,19.5s-1.083-1-1.303-1.531
		c-0.228-0.55,0.253-1.59-0.161-2.004c-0.136-0.136-0.338-0.175-0.569-0.175c-0.325,0-0.706,0.079-1.032,0.079
		c-0.149,0-0.287-0.017-0.404-0.065C24.5,15.583,24.11,14.5,23.5,14.5s-1,1.083-1.531,1.303c-0.116,0.048-0.254,0.065-0.403,0.065
		c-0.326,0-0.707-0.079-1.032-0.079c-0.23,0-0.433,0.04-0.569,0.175c-0.414,0.414,0.067,1.455-0.161,2.005
		C19.583,18.5,18.5,18.89,18.5,19.5c0,0.61,1.083,1,1.303,1.531c0.228,0.55-0.253,1.59,0.161,2.004
		c0.136,0.136,0.338,0.175,0.569,0.175c0.325,0,0.706-0.079,1.032-0.079c0.149,0,0.287,0.017,0.404,0.065
		C22.5,23.417,22.89,24.5,23.5,24.5s1-1.083,1.531-1.303c0.116-0.048,0.254-0.065,0.403-0.065c0.326,0,0.707,0.079,1.032,0.079
		C26.697,23.211,26.9,23.171,27.035,23.036z M31,7H1v19h19v-1.858c-0.292-0.072-0.544-0.201-0.743-0.4
		c-0.571-0.571-0.485-1.34-0.423-1.902c0.015-0.137,0.038-0.336,0.044-0.406c-0.069-0.07-0.21-0.182-0.309-0.26
		C18.143,20.839,17.5,20.33,17.5,19.5s0.643-1.339,1.069-1.676c0.094-0.075,0.227-0.18,0.299-0.25
		c0.001-0.106-0.02-0.287-0.034-0.415c-0.063-0.562-0.149-1.331,0.423-1.902c0.311-0.311,0.74-0.468,1.276-0.468
		c0.218,0,0.435,0.024,0.626,0.046c0.139,0.016,0.278,0.033,0.406,0.033c0.003,0,0.006,0,0.01,0c0.07-0.072,0.175-0.205,0.25-0.299
		C22.161,14.143,22.67,13.5,23.5,13.5s1.339,0.643,1.675,1.069c0.074,0.094,0.179,0.227,0.25,0.299c0,0,0,0,0,0
		c0.003,0,0.006,0,0.009,0c0.128,0,0.267-0.018,0.406-0.033c0.191-0.021,0.408-0.046,0.626-0.046c0.536,0,0.965,0.158,1.276,0.468
		c0.571,0.571,0.485,1.341,0.423,1.902c-0.015,0.137-0.038,0.336-0.043,0.405c0.069,0.07,0.21,0.182,0.309,0.26
		C28.857,18.161,29.5,18.67,29.5,19.5s-0.643,1.339-1.069,1.675c-0.094,0.075-0.227,0.18-0.299,0.25
		c-0.001,0.106,0.02,0.287,0.034,0.415c0.063,0.562,0.149,1.331-0.423,1.902c-0.198,0.198-0.45,0.327-0.743,0.399V26h4V7z"
      />
    </g>
  </svg>
)

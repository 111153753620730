import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import './index.css'
import store from './Components/ReduxToolkit/store'
import { Provider } from 'react-redux'
import { AppRouter } from './Components/Routes/Router'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <RouterProvider router={AppRouter} />
  </Provider>,
)

import React, { useState } from 'react'
import { Form, Input, Button, Checkbox, Row, Col, Divider } from 'antd'
import { CircleClip } from '../../Utils/CustomClip/CircleClip'
import { LinkedInSvg } from '../../Assets/LinkedInSvg'
import { GoogleSvg } from '../../Assets/GoogleSvg'

const LoginForm = () => {
  const [passwordVisible, setPasswordVisible] = useState(false)

  const handleLogin = (values) => {
    console.log('Form values:', values)
  }

  return (
    <div style={{ overflow: 'hidden' }}>
      <div style={{ position: 'relative', zIndex: 2 }}>
        <Row justify="center">
          <span
            style={{
              color: 'gray',
              textAlign: 'center',
              fontSize: '20px',
              fontWeight: '300',
            }}
          >
            SAM LMS
          </span>
        </Row>
        <Row
          justify="center"
          style={{
            margin: '16px 0',
            color: 'gray',
            fontSize: '14px',
            fontWeight: '300',
            display: 'flex',
            rowGap: '8px',
          }}
        >
          <div
            style={{
              fontSize: '14px',
            }}
          >
            Login to your account.
          </div>
          <div>Welcome back! Select a method to login.</div>
        </Row>
        <Row gutter={16} justify="center" style={{ margin: '16px 0' }}>
          <Col>
            <Button
              icon={<GoogleSvg />}
              style={{ width: '100%', border: 'none', borderRadius: '25px' }}
            >
              Google
            </Button>
          </Col>
          <Divider
            type="vertical"
            style={{
              marginTop: '8px',
              height: '20px',
              borderRadius: '100%',
              borderWidth: '1px',
              fontSize: '12px',
              borderColor: 'black',
            }}
          />
          <Col>
            <Button icon={<LinkedInSvg />} style={{ width: '100%', border: 'none' }}>
              LinkedIn
            </Button>
          </Col>
        </Row>
        <Row justify="center" style={{ margin: '10px 0', fontSize: '12px', color: 'grey' }}>
          <span>Or continue with email or mobile</span>
        </Row>
        <Form name="login" initialValues={{ remember: true }} onFinish={handleLogin}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please input your email/phone!' }]}
          >
            <Input placeholder="Email or Phone" style={{ borderRadius: '25px' }} />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              placeholder="Password"
              visibilityToggle={{
                visible: passwordVisible,
                onClick: () => setPasswordVisible(!passwordVisible),
              }}
              style={{ borderRadius: '25px' }}
            />
          </Form.Item>

          <Row gutter={16} align="middle">
            <Col span={12}>
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
            </Col>
            <Col span={12} style={{ textAlign: 'right', marginBottom: '18px' }}>
              <a href="/forgot-password">Forgot password?</a>
            </Col>
          </Row>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: '100%', borderRadius: '25px' }}
            >
              Log In
            </Button>
          </Form.Item>
        </Form>
      </div>
      <CircleClip />
    </div>
  )
}

export default LoginForm

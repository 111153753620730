import React from 'react'
import './App.css'
import Header from './Components/Features/Header/Header'
import 'typeface-poppins'
import { Outlet } from 'react-router-dom'
import { Footer } from '../src/Components/Features/Footer/Footer'

function App() {
  return (
    <div id="App">
      <Header />
      <Outlet />
      <Footer />
    </div>
  )
}

export default App

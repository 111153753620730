import React from 'react'
import { Button, Card, Row, Tag } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import '../CourseCard/CourseCard.css'
import { useNavigate } from 'react-router-dom'

export const CourseCard = (props) => {
  const course = props?.course
  const navigate = useNavigate()

  return (
    <div className="curved-card-container">
      <Card
        className="coursecard"
        cover={<img alt="example" src={course?.img} className="cover-image" />}
      >
        <div className="content">
          <Row>
            <div
              style={{
                fontSize: '14px',
                fontWeight: '600',
                borderRadius: '11px',
              }}
            >
              {course?.title}
            </div>
          </Row>
          <Row
            className="course-basic"
            style={{
              justifyContent: 'space-between',
            }}
          >
            <Tag color="geekblue" style={{ fontSize: '10px', borderRadius: '5px' }}>
              Duration 1h 15m{' '}
            </Tag>{' '}
            <Tag color="geekblue" style={{ fontSize: '10px', borderRadius: '5px' }}>
              12 lessons
            </Tag>
          </Row>
          <Row>
            <div
              className="course-description"
              style={{
                width: '100%',
                minHeight: '30px',
                textWrap: 'pretty',
                borderRadius: '11px',
                fontSize: '12px',
                textAlign: 'left',
              }}
            >
              {course?.description}
            </div>
          </Row>
          <Row>
            <div
              className="course-outcome"
              style={{
                fontSize: '12px',
                fontWeight: '600',
              }}
            >
              Career Outcome
            </div>
          </Row>
          <Row style={{ paddingBlockEnd: '10px' }}>
            <div
              className="course-outcome"
              style={{
                whiteSpace: 'pretty',
                width: '100%',
                textWrap: 'pretty',
                fontSize: '12px',
                textAlign: 'left',
              }}
            >
              {course?.careerOutcome}
            </div>
          </Row>
          <Row
            className="enrollment-row"
            style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button className="enroll-button" onClick={() => navigate('/course_view')}>
              Enroll
              <ArrowRightOutlined className="enroll-icon" />
            </Button>
          </Row>
          <Row className="clipArt">
            <svg
              className="sine-wave-background"
              viewBox="0 0 100 10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 0 Q 10 10, 20 0 T 40 0 T 60 0 T 80 0 T 100 0 V 10 H 0 Z"
                fill="#c2cbc95b"
              />
            </svg>
          </Row>
        </div>
      </Card>
    </div>
  )
}

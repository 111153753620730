import React, { useRef, useState } from 'react'
import { Form, Input, Button, Row, Col, Divider, message } from 'antd'
import { emailRegex, mobileRegex, onlyNumbersRegex } from '../../Utils/Validations/Validations'
import { CircleClip } from '../../Utils/CustomClip/CircleClip'
import { GoogleSvg } from '../../Assets/GoogleSvg'
import { LinkedInSvg } from '../../Assets/LinkedInSvg'

const RegisterForm = () => {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [otpScreen, setOtpScreen] = useState(false)
  let noOfOtpForms = [1, 2, 3, 4]
  const inputsRef = useRef([])
  const [messageApi, contextHolder] = message.useMessage()
  const Toast = (text, type) => {
    messageApi.open({
      type: type,
      content: text,
      className: 'toast',
    })
  }
  const handleRegister = (values) => {
    let validate = null
    if (emailRegex === true) {
      console.log('email')
      validate = 'email'
    }
    if ((mobileRegex && values.email.length === 10) === true) {
      console.log('mobile')
      validate = 'mobile'
    }
    if (validate === 'email' || validate === 'mobile') {
      setOtpScreen(true)
      console.log('success')
      Toast('Successfully registered', 'success')
    } else {
      Toast('Invalid email or mobile no', 'error')
    }
  }
  const handleOtp = (values) => {
    let validations = [false]
    values.map((item) => {
      if (onlyNumbersRegex(item)) {
        validations = []
        validations.push(true)
      }
      return ''
    })
    if (!validations.includes(false)) {
      Toast('success', 'success')
    }
  }
  const handleChange = (e, index) => {
    const { value } = e.target
    if (value.length === 1 && index < inputsRef.current.length - 1) {
      inputsRef.current[index + 1].focus()
    }
  }

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && !e.target.value) {
      inputsRef.current[index - 1].focus()
    }
  }

  const otpForm = () =>
    noOfOtpForms.map((item, idx) => (
      <Form.Item key="" name={idx} rules={[{ required: true }]}>
        <Input
          style={{
            borderRadius: '10px',
            width: '40px',
            height: '40px',
            boxShadow: '0px 8px 8px #5894bf',
          }}
          maxLength={1}
          onChange={(e) => handleChange(e, idx)}
          onKeyDown={(e) => handleKeyDown(e, idx)}
          ref={(el) => (inputsRef.current[idx] = el)}
        />
      </Form.Item>
    ))

  return (
    <div>
      {contextHolder}
      {!otpScreen ? (
        <div style={{ overflow: 'hidden' }}>
          <div style={{ zIndex: 2, position: 'relative' }}>
            <Row justify="center">
              <span
                style={{
                  color: 'gray',
                  textAlign: 'center',
                  fontSize: '20px',
                  fontWeight: 'lighter',
                }}
              >
                SAM LMS
              </span>
            </Row>
            <Row
              justify="center"
              style={{
                margin: '16px 0',
                color: 'gray',
                fontSize: '14px',
                fontWeight: '300',
                display: 'flex',
                rowGap: '8px',
              }}
            >
              <div
                style={{
                  fontSize: '14px',
                }}
              >
                Register your account.
              </div>
              <div>Welcome back! Select a method to register.</div>
            </Row>
            <Row gutter={16} justify="center" style={{ margin: '16px 0' }}>
              <Col>
                <Button
                  icon={<GoogleSvg />}
                  style={{
                    width: '100%',
                    border: 'none',
                    borderRadius: '25px',
                  }}
                >
                  Google
                </Button>
              </Col>
              <Divider
                type="vertical"
                style={{
                  marginTop: '8px',
                  height: '20px',
                  borderRadius: '100%',
                  borderWidth: '1px',
                  fontSize: '12px',
                  borderColor: 'black',
                }}
              />
              <Col>
                <Button icon={<LinkedInSvg />} style={{ width: '100%', border: 'none' }}>
                  LinkedIn
                </Button>
              </Col>
            </Row>
            <Row justify="center" style={{ margin: '10px 0', fontSize: '12px', color: 'grey' }}>
              <span>Or continue with email or mobile</span>
            </Row>
            <Form
              name="register"
              initialValues={{ remember: true }}
              onFinish={handleRegister}
              disabled={false}
            >
              <Form.Item
                name="userName"
                rules={[{ required: true, message: 'Please input your name!' }]}
              >
                <Input placeholder="Full name" style={{ borderRadius: '25px' }} />
              </Form.Item>

              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Please input your email/phone!' }]}
              >
                <Input placeholder="Email or Phone" style={{ borderRadius: '25px' }} />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password
                  placeholder="Password"
                  visibilityToggle={{
                    visible: passwordVisible,
                    onClick: () => setPasswordVisible(!passwordVisible),
                  }}
                  style={{ borderRadius: '25px' }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%', borderRadius: '25px' }}
                >
                  SIGNUP
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      ) : (
        <div style={{ zIndex: '3', position: 'relative' }}>
          <Col
            style={{
              padding: '20% 0px 0px 0px',
            }}
          >
            <Row justify="center">
              <span
                style={{
                  color: 'gray',
                  textAlign: 'center',
                  fontSize: '20px',
                  fontWeight: 'lighter',
                }}
              >
                SAM LMS
              </span>
            </Row>

            <center style={{ fontSize: '18px', color: '#bd0b3b' }}>OTP</center>
            <div style={{ color: '#b5b0b1', margin: '10px 0px 20px 0px' }}>
              A four digit code has been sent to eMail or mobile number{' '}
            </div>
            <Form name="otp" onFinish={handleOtp}>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-evenly',
                }}
              >
                {otpForm()}
              </div>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    width: '100%',
                    borderRadius: '25px',
                    marginTop: '10px',
                  }}
                >
                  SUBMIT
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </div>
      )}
      <div style={{ position: 'absolute', top: '0' }}>
        <CircleClip />
      </div>
    </div>
  )
}

export default RegisterForm

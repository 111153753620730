import { Avatar, Button, Dropdown, Menu } from 'antd'
import React, { useState } from 'react'
import './Header.css'
import { CloseCircleOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const Header = () => {
  const [profileCard, setShowProfileCard] = useState(false)
  const [selectedItem, setSelectedItem] = useState(['home'])

  const navigate = useNavigate()
  const myObject = JSON.parse(localStorage.getItem('userObj'))
  const profileItems = [
    {
      key: 'user-profile',
      label: 'Profile',
    },
    {
      key: 'user-payment',
      label: 'Payment & History',
    },
    {
      key: 'user-logout',
      label: 'Logout',
    },
  ]
  const headerItems = [
    {
      key: '#home',
      label: 'Home',
    },
    {
      key: '#courses',
      label: 'Courses',
    },
    {
      key: '#testimonials',
      label: 'Testimonials',
    },
    {
      key: '#about',
      label: 'About',
    },
  ]
  const handleMainHeaderNavigation = (e) => {
    const selectItem = e?.key
    setSelectedItem([selectItem])
    setShowProfileCard(false)
    navigate('/')
  }

  const onClick = (e) => {
    const selectItem = e?.key
    setSelectedItem([selectItem])
    setShowProfileCard(false)
    if (selectItem === 'user-profile') navigate('/profile')
  }
  return (
    <div className="header">
      <Dropdown
        className="header-menu-vertical"
        overlay={
          <Menu
            selectedKeys={selectedItem}
            defaultSelectedKeys={['home']}
            onClick={handleMainHeaderNavigation}
          >
            {headerItems.map((data) => (
              <Menu.Item className="item" key={data?.key}>
                <a className="nav-item" href={data?.key}>
                  {data?.label}
                </a>
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Button type="text" icon={<MenuOutlined />} style={{ marginLeft: '25px' }} />
      </Dropdown>
      <Menu
        mode="horizontal"
        className="header-menu-horizontal"
        selectedKeys={selectedItem}
        defaultSelectedKeys={['home']}
        onClick={handleMainHeaderNavigation}
        style={{ width: '100%', border: 'none' }}
      >
        {headerItems.map((data) => (
          <Menu.Item className="item" key={data?.key}>
            <a className="nav-item" href={data?.key}>
              {data?.label}
            </a>
          </Menu.Item>
        ))}
      </Menu>
      {myObject?.login && (
        <div className="user-menu-card">
          <Dropdown
            placement="bottomRight"
            overlay={
              <Menu>
                {profileItems.map((data) => (
                  <Menu.Item key={data?.key} onClick={onClick}>
                    {data?.label}
                  </Menu.Item>
                ))}{' '}
              </Menu>
            }
          >
            <Button
              type="text"
              icon={
                <div
                  className="profile-card"
                  onClick={() => setShowProfileCard(!profileCard)}
                  style={{ marginRight: '10px' }}
                >
                  <p className="username">John Doe</p>
                  <Avatar icon={<UserOutlined />} />
                </div>
              }
              style={{ marginLeft: 16 }}
            />
          </Dropdown>
        </div>
      )}
    </div>
  )
}

export default Header

import React from 'react'
import './Footer.css'
export const Footer = () => (
  <footer id="about" className="footer">
    {/* <h2>About Us</h2> */}
    Our mission is to provide high-quality education accessible to everyone. We offer a variety of
    courses tailored to meet your learning needs.
    {/* <p>
      Our mission is to provide high-quality education accessible to everyone. We offer a variety of
      courses tailored to meet your learning needs.
    </p> */}
  </footer>
)

export const termsData = [
  'DevOps Methodology',
  'Continuous Integration',
  'Continuous Delivery',
  'Configuration Management',
  'Containerization',
  'DevSecOps',
  'Azure DevOps',
  'Logging and Monitoring',
  'Orchestration',
  'Cloud Platforms',
]

export const CourseContent = [
  {
    title: 'INTRODUCTION',
    duration: '20min',
    content: [
      {
        title: 'About this course',
        duration: '3min 2sec',
      },
      {
        title: 'Introduction',
        duration: '3min 2sec',
      },
      {
        title: 'What is Devops?',
        duration: '3min 2sec',
      },
      {
        title: 'What is continuous integration',
        duration: '3min 2sec',
      },
    ],
  },
  {
    title: 'PREREQUISITES AND INFO SETUP',
    duration: '20min',
    content: [
      {
        title: 'About this course',
        duration: '3min 2sec',
      },
      {
        title: 'Introduction',
        duration: '3min 2sec',
      },
      {
        title: 'What is Devops?',
        duration: '3min 2sec',
      },
      {
        title: 'What is continuous integration',
        duration: '3min 2sec',
      },
    ],
  },
  {
    title: 'VM SETUP',
    duration: '20min',
    content: [
      {
        title: 'About this course',
        duration: '3min 2sec',
      },
      {
        title: 'Introduction',
        duration: '3min 2sec',
      },
      {
        title: 'What is Devops?',
        duration: '3min 2sec',
      },
      {
        title: 'What is continuous integration',
        duration: '3min 2sec',
      },
    ],
  },
  {
    title: 'LINUX',
    duration: '20min',
    content: [
      {
        title: 'About this course',
        duration: '3min 2sec',
      },
      {
        title: 'Introduction',
        duration: '3min 2sec',
      },
      {
        title: 'What is Devops?',
        duration: '3min 2sec',
      },
      {
        title: 'What is continuous integration',
        duration: '3min 2sec',
      },
    ],
  },
  {
    title: 'VAGRANT AND LINUX SERVERS',
    duration: '20min',
    content: [
      {
        title: 'About this course',
        duration: '3min 2sec',
      },
      {
        title: 'Introduction',
        duration: '3min 2sec',
      },
      {
        title: 'What is Devops?',
        duration: '3min 2sec',
      },
      {
        title: 'What is continuous integration',
        duration: '3min 2sec',
      },
    ],
  },
  {
    title: 'VARIABLES JSON AND YAML',
    duration: '20min',
    content: [
      {
        title: 'About this course',
        duration: '3min 2sec',
      },
      {
        title: 'Introduction',
        duration: '3min 2sec',
      },
      {
        title: 'What is Devops?',
        duration: '3min 2sec',
      },
      {
        title: 'What is continuous integration',
        duration: '3min 2sec',
      },
    ],
  },
  {
    title: 'VPROFILE PROJECT AND SETUP',
    duration: '20min',
    content: [
      {
        title: 'About this course',
        duration: '3min 2sec',
      },
      {
        title: 'Introduction',
        duration: '3min 2sec',
      },
      {
        title: 'What is Devops?',
        duration: '3min 2sec',
      },
      {
        title: 'What is continuous integration',
        duration: '3min 2sec',
      },
    ],
  },
  {
    title: 'NETWORKING',
    duration: '20min',
    content: [
      {
        title: 'About this course',
        duration: '3min 2sec',
      },
      {
        title: 'Introduction',
        duration: '3min 2sec',
      },
      {
        title: 'What is Devops?',
        duration: '3min 2sec',
      },
      {
        title: 'What is continuous integration',
        duration: '3min 2sec',
      },
    ],
  },
]

export const courseProjects = [
  {
    height: '220px',
    width: '200px',
    title: 'ASI Insurance',
    description:
      'Create a DevOps pipeline for an insurance company to build and deploy a microservice application architecture on Docker.',
    color: '#2563EB',
    backgroundColor: '#749aec3b',
  },
  {
    height: '220px',
    width: '200px',
    title: 'Orbit Bank',
    description:
      'Deploy a banking application that helps private banking clients manage their accounting on a Kubernetes cluster from Docker Hub.',
    color: 'rgb(135, 74, 197)',
    backgroundColor: 'rgba(165, 125, 204, 0.226)',
  },
  {
    height: '220px',
    width: '200px',
    title: 'HotelSide Hospital',
    description:
      'Automate and provision infrastructure using Terraform, EKS cluster, EC2 instances, and Jenkins server.',
    color: '#FC8500',
    backgroundColor: '#f5c69141',
  },
]

export const StampData = [
  {
    title: 'Certificate of completion',
    type: 'certification',
  },
  {
    title: 'Course duration 70hrs 15min',
    type: 'duration',
  },
  {
    title: 'Lessions 10',
    type: 'lessions',
  },
  {
    title: 'Level up your skills',
    type: 'levelup',
  },
]

export const ToolsSize = {
  height: '50px',
  width: '50px',
}

export const CourseInfo = {
  course_title: 'Devops training certification',
  course_sub_title: 'Master DevOps Engineer Course Online',
  course_image: 'https://i.pinimg.com/564x/b9/90/b9/b990b9b5ea80ef423092bbe9bfd822ef.jpg',
  course_preview_image: 'https://i.pinimg.com/736x/b6/ca/9a/b6ca9aa53cf9b7d19d28c28f9f2fca06.jpg',
  course_content: [
    {
      key: 'Course Includes',
      value: StampData,
      display_type: 'AnimatedSlider',
    },
    { key: 'Tools Covered', value: '', display_type: '' },
    {
      key: 'Career Outcome',
      value: 'System Reliability Engineer (SRE), Platform Engineer, Cloud Engineer/Architect',
      display_type: 'paragraph',
    },
    {
      key: 'Requirements',
      value: 'Basic Computer Knowledge',
      display_type: 'paragraph',
    },
    {
      key: 'Capstone Projects',
      value: courseProjects,
      display_type: 'UspCard',
    },
    {
      key: 'Description',
      value:
        'This course is for anybody who wants to get started with DevOps: As there are many tools & technologies in DevOps, it has become difficult for people to choose the right technologies. This course starts from very basics of command line, hands on demonstrations of many tools & technologies. Also most importantly it will show you how various technologies in DevOps work together by setting up your own projects.',
      display_type: 'paragraph',
    },
    {
      key: 'Learning Insight',
      value:
        'Python Fundamentals, Data Structures and Algorithms, Linux, DevOps Tools, AWS, System Design, Advanced DSA',
      display_type: 'paragraph',
    },
    { key: "What You'll Learn", value: termsData, display_type: 'List' },
  ],
}

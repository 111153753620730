import React from 'react'

export const CircleClip = (props) => {
  const width = props.data !== undefined ? props.data?.width : '200'
  const height = props.data !== undefined ? props.data?.width : '300'
  return (
    <div className="clip-art">
      <div style={{ position: 'relative', zIndex: 1 }}>
        <svg
          width={width}
          height={height}
          viewBox="0 0 200 300"
          style={{
            position: 'absolute',
            top: '0%',
            right: '0%',
            left: '0%',
            opacity: '0.1',
          }}
        >
          <circle className="circle one" cx="100" cy="10" r="70" fill="gray" />
        </svg>
      </div>
      <div style={{ position: 'relative', zIndex: 0 }}>
        <svg
          width={width}
          height={height}
          viewBox="0 0 200 300"
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',
            opacity: '0.11',
          }}
        >
          <circle className="circle one" cx="100" cy="10" r="100" fill="gray" />
        </svg>
      </div>
    </div>
  )
}

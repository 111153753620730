const emailRegexTest =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i
const mobileRegexTest = /^[6789]\d{9}$/
const onlyMobileRegexTest = /^[0-9]+$/

export const emailRegex = (email) => {
  return emailRegexTest.test(email)
}
export const mobileRegex = (mobileNo) => {
  return mobileRegexTest.test(mobileNo)
}
export const onlyNumbersRegex = (mobileNo) => {
  return onlyMobileRegexTest.test(mobileNo)
}

import React, { useState, useEffect } from 'react'
import {
  PieChartOutlined,
  CalendarOutlined,
  BookOutlined,
  SettingOutlined,
  IdcardOutlined,
  RobotOutlined,
  RightOutlined,
} from '@ant-design/icons'
import './Profile.css'
import {
  Layout,
  Menu,
  Card,
  Row,
  Col,
  Avatar,
  Descriptions,
  Divider,
  Timeline,
  Calendar,
} from 'antd'
import 'react-calendar/dist/Calendar.css'
import { CircleClip } from '../../Utils/CustomClip/CircleClip'

const UserProfile = () => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [selectedMenuItem, setSelectedMenuItem] = useState('1')
  const [daysOfWeek, setDaysOfWeek] = useState([])
  const [calendarVisible, setCalendarVisible] = useState(false)
  const { Content, Sider } = Layout

  const items1 = [
    { label: 'Email', value: 'xyz@gmail.com' },
    { label: 'Phone', value: '8999888998' },
    { label: 'Emergency Contact', value: '8999888998' },
  ]

  const items2 = [
    { label: 'D.O.B', value: '22-02-2000' },
    { label: 'Profession', value: 'Student' },
    { label: 'Instution', value: 'Easy Tech University' },
    { label: 'City', value: 'Hyderabad' },
    { label: 'State', value: 'Telangana' },
  ]
  const items3 = [
    { label: 'Education', value: 'BTech' },
    { label: 'Instution', value: 'Easy Tech University' },
    { label: 'Enrollment Date', value: '22-02-2017' },
  ]

  const items = [
    getItem('Profile', '1', <IdcardOutlined />),
    getItem('Track Performance', '2', <PieChartOutlined />),
    getItem('Exams', '3', <BookOutlined />),
    getItem('Settings', '4', <SettingOutlined />),
    getItem('Help & Support', '5', <RobotOutlined />),
  ]

  const getDaysOfWeek = () => {
    const days = []
    const today = new Date()
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

    for (let i = -3; i <= 3; i++) {
      const date = new Date(today)
      date.setDate(today.getDate() + i)
      const day = {
        day: dayNames[date.getDay()],
        date: date.getDate(),
        isToday: i === 0,
      }
      days.push(day)
    }

    return days
  }

  useEffect(() => {
    console.log(getDaysOfWeek())
    setDaysOfWeek(getDaysOfWeek())
  }, [])

  function getItem(label, key, icon, children) {
    return {
      key: key,
      icon: icon,
      children: children,
      label: <span style={{ fontSize: '12px' }}>{label}</span>,
    }
  }

  const getCurrentMonthAndYear = () => {
    const today = new Date()
    const options = { month: 'long', year: 'numeric' }
    return today.toLocaleDateString('en-US', options)
  }

  const onPanelChange = (value, mode) => {
    console.log(value.format('YYYY-MM-DD'), mode)
  }

  const renderContent = () => {
    switch (selectedMenuItem) {
      case '1':
        return (
          <Card
            className="left-aligned-title"
            style={{ position: 'relative', marginTop: '14px', padding: '5px' }}
            title={
              <div>
                <p>
                  <span style={{ fontSize: '18px', fontWeight: '400' }}>Student Profile</span>
                  <span style={{ float: 'right' }}>
                    <CalendarOutlined onClick={() => setCalendarVisible(!calendarVisible)} />
                    {calendarVisible && (
                      <Card
                        style={{ width: '350px', position: 'absolute', right: 0, zIndex: 1000 }}
                      >
                        <Calendar
                          fullscreen={false}
                          onChange={onPanelChange}
                          onPanelChange={onPanelChange}
                        />
                      </Card>
                    )}
                  </span>
                </p>
              </div>
            }
          >
            <Row className="profile-info-container">
              <div className="profile-info-widget-1">
                <Card style={{ height: '420px' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Avatar size={70} icon="VH" style={{ background: 'blue' }} />
                    <div style={{ marginLeft: '10px' }}>
                      <Row style={{ fontSize: '14px', fontWeight: '400' }}>Harish Vukkadapu</Row>
                      <Row
                        style={{
                          color: 'gray',
                          fontSize: '12px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div>Id: 23344</div>
                        <div>Gender: M</div>
                      </Row>
                    </div>
                  </div>
                  <Divider />
                  <Descriptions
                    size="small"
                    column={1}
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {items2.map((item) => (
                      <Descriptions.Item
                        label={
                          <span style={{ fontSize: '12px', width: '100px' }}>{item.label}</span>
                        }
                        key={item.label}
                      >
                        <span style={{ fontSize: '12px' }}>{item.value}</span>
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                  <Divider />
                  <div style={{ fontSize: '12px', fontWeight: '400', color: 'gray' }}>
                    Subscription ends in May 2026
                  </div>
                  <div style={{ position: 'absolute', top: '0', left: '40%' }}>
                    <CircleClip data={{ height: '200px', width: '150px' }} />
                  </div>
                </Card>
              </div>
              <div className="profile-info-widget-2">
                <Card
                  title={
                    <span style={{ fontSize: '14px', fontWeight: '400' }}>Contact Details</span>
                  }
                  className="left-aligned-title"
                  style={{ marginLeft: '11px', fontSize: '12px' }}
                >
                  <Descriptions size="small" column={1}>
                    {items1.map((item) => (
                      <Descriptions.Item
                        label={
                          <span style={{ fontSize: '12px', width: '100px' }}>{item.label}</span>
                        }
                        key={item.label}
                      >
                        <span style={{ fontSize: '12px' }}>{item.value}</span>
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                  <div
                    style={{ position: 'absolute', bottom: '0', rotate: '180deg', left: '100%' }}
                  >
                    <CircleClip data={{ height: '200px', width: '150px' }} />
                  </div>
                </Card>
                <Card
                  title={
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                      }}
                    >
                      Academic Information
                    </span>
                  }
                  className="left-aligned-title"
                  style={{ marginTop: '20px', marginLeft: '11px' }}
                >
                  <Descriptions size="small" column={1}>
                    {items3.map((item) => (
                      <Descriptions.Item
                        label={
                          <span style={{ fontSize: '12px', width: '100px' }}>{item.label}</span>
                        }
                        key={item.label}
                      >
                        <span style={{ fontSize: '12px' }}>{item.value}</span>
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                  <div
                    style={{ position: 'absolute', bottom: '0', rotate: '180deg', left: '100%' }}
                  >
                    <CircleClip data={{ height: '200px', width: '150px' }} />
                  </div>
                </Card>
              </div>
              <div className="profile-info-widget-3">
                <Card
                  style={{ marginLeft: '11px', height: '400px' }}
                  title={
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: '14px',
                        fontWeight: '400',
                      }}
                    >
                      <span>Current Task</span>
                      <span>{getCurrentMonthAndYear()}</span>
                    </Row>
                  }
                >
                  <Row style={{ justifyContent: 'space-between' }}>
                    {daysOfWeek.map((day) => (
                      <Col className="days-with" span={3} key={day.day}>
                        <div
                          className="days-with-date"
                          style={{
                            fontSize: '14px',
                            color: '#346ae0',
                            textAlign: 'center',
                            borderRadius: '7px',
                            padding: '6px 0px',
                            background: day.isToday ? '#346ae048' : '#f0f2f5',
                            marginBottom: '8px',
                          }}
                        >
                          <strong style={{ fontSize: '12px', fontWeight: '300' }}>{day.day}</strong>
                          <div style={{ fontSize: '12px' }}>{day.date}</div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  <Timeline
                    style={{ marginTop: '25px', fontSize: '14px' }}
                    items={[
                      {
                        children: (
                          <Card
                            style={{
                              width: '100%',
                              backgroundColor: '#eeeee4',
                            }}
                          >
                            <div style={{ fontSize: '14px', fontWeight: '300' }}>Meeting</div>

                            <div style={{ fontSize: '12px', fontWeight: '300' }}>
                              Meeting discussion of the day
                            </div>
                          </Card>
                        ),
                      },
                      {
                        children: (
                          <span style={{ fontSize: '12px', color: 'gray' }}>
                            Technical testing 2015-09-01
                          </span>
                        ),
                      },
                    ]}
                  />
                  <div className="clip-art" style={{ position: 'absolute', top: '0', left: '50%' }}>
                    <CircleClip data={{ height: '200px', width: '150px' }} />
                  </div>
                </Card>
              </div>
            </Row>
          </Card>
        )
      case '2':
        return <Card title="Track Performance"></Card>
      case '3':
        return (
          <Card title="Exams">
            <p>Exam details will be displayed here.</p>
          </Card>
        )
      case '4':
        return <Card title="Settings"></Card>
      case '5':
        return <Card title="Help & Support"></Card>
      default:
        return null
    }
  }

  return (
    <Layout style={{ minHeight: '100%' }}>
      <Sider collapsible={true} collapsed={isCollapsed}>
        <Menu
          theme="light"
          defaultSelectedKeys={['1']}
          mode="inline"
          items={items}
          onClick={(e) => setSelectedMenuItem(e.key)}
        />
        <div
          className="menu-collapse-button"
          style={{
            color: 'rgb(134, 154, 223)',
            display: 'flex',
            justifyContent: 'center',
            margin: '20px',
            borderRadius: '100px',
            padding: '10px',
            background: '#e6f4ff',
          }}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <RightOutlined />
        </div>
      </Sider>
      <Layout>
        <Content style={{ margin: '0 16px' }}>{renderContent()}</Content>
      </Layout>
    </Layout>
  )
}

export default UserProfile
